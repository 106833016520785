/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import './styles.scss';
import 'bootstrap';

export const onClientEntry = () => {
  if (typeof window.IntersectionObserver === 'undefined') {
    import('intersection-observer');
  }
  window.recaptchaOptions = {
    useRecaptchaNet: true,
  };
};
